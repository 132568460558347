// src/components/Roadmap/Roadmap.js

import React from "react";
import styles from "./AboutUs.module.css"; // Ensure this CSS file includes the styles for your AboutUs section
import backgroundVideo from "../../assets/boutus.mp4"; // Import the background video

const AboutUs = () => {
  return (
    <div className={styles.AboutUs}>
      <video autoPlay loop muted playsInline className={styles.backgroundVideo}>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h1 className={styles.AboutUsTitle}></h1>
      {/* Insert the rest of your AboutUs content here */}
    </div>
  );
};

export default AboutUs;
