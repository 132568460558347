// Footer.js
import React from "react";
import styles from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faDiscord, faTelegram } from "@fortawesome/free-brands-svg-icons";

function Footer({ isNftCollectionPage }) {
  return (
    <footer className={`${styles.footer} ${isNftCollectionPage ? styles.nftCollectionFooter : ""}`}>
      <div className={`${styles.footerContent} ${isNftCollectionPage ? styles.nftCollectionFooterContent : ""}`}>
        {/* Optional: Add conditional content for NFT Collection page */}
      </div>
      <div className={styles.footerBottom}>
        <hr className={styles.horizontalRule} />
        <div className={styles.socialLinks}>
          <a
            href="https://twitter.com/FantomBonkInu/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>

          {/* ... more social links if you want ... */}
        </div>
        {isNftCollectionPage ? <p>NFT Collection Specific Text</p> : <p>© 2023 | Just a Fantom Meme Coin | $FTM |</p>}
      </div>
    </footer>
  );
}

export default Footer;
