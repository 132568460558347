import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import iconImage from "../../assets/NavBarIcon.png";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

function Home() {
  // State to track if the countdown has finished
  const [hasCountdownEnded, setHasCountdownEnded] = useState(false);
  const targetDate = "2024-01-23T20:00:00Z"; // Set your desired target date

  useEffect(() => {
    const onMouseMove = (e) => {
      const cursor = document.getElementById("custom-cursor");
      cursor.style.left = e.pageX + "px";
      cursor.style.top = e.pageY + "px";
    };

    document.addEventListener("mousemove", onMouseMove);

    // Check if the countdown should end
    const interval = setInterval(() => {
      const now = new Date();
      const countdownEndDate = new Date(targetDate);
      if (now >= countdownEndDate) {
        setHasCountdownEnded(true);
        clearInterval(interval);
      }
    }, 1000); // Check every second

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <main className={styles.Home} style={{ backgroundColor: "black" }}>
      <div id="custom-cursor" className={styles.animatedCursor}></div>
      <div className={styles.contentOverlay}>
        <img src={iconImage} alt="Icon" className={styles.mascotImage} />
        <CountdownTimer targetDate={targetDate} />
        {hasCountdownEnded && (
          <>
            <h1 className={styles.tagline}>CA:0xc849255332F26Da482d0a62dC12B4cdCb330B4dd</h1>
            <a
              href="https://spooky.fi/#/swap"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.buyNowButton}
            >
              Buy It Now
            </a>
          </>
        )}
      </div>
    </main>
  );
}

export default Home;
