// src/components/Roadmap/Roadmap.js

import React from "react";
import styles from "./Roadmap.module.css"; // Ensure this CSS file includes the styles for your roadmap
import backgroundImage from "../../assets/Phase1.png"; // Import the background image

const Roadmap = () => {
  return (
    <div
      className={styles.roadmap}
      style={{ backgroundImage: `url(${backgroundImage})` }} // Inline style for background image
    >
      <h1 className={styles.roadmapTitle}></h1>
      {/* Insert the rest of your roadmap content here */}
    </div>
  );
};

export default Roadmap;
