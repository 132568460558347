import React from "react";
import styles from "./GameFi.module.css";

const GameFi = () => {
  return (
    <div className={styles.GameFi}>
      <h1 className={styles.GameFiTitle}>Play FantomBonkinu</h1>
      <div className={styles.responsiveIframeContainer}>
        <iframe
          src="https://fantombonkinu.github.io/FantomBonkInuGame/"
          frameBorder="0"
          allowFullScreen
          className={styles.responsiveIframe}
        ></iframe>
      </div>
      {/* Additional content can go here */}
    </div>
  );
};

export default GameFi;
