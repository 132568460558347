//Header.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import iconImage from "../../assets/IconForSite.png"; // Update with your actual path and filename

function Header() {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className={styles.header}>
      <img src={iconImage} alt="Site Icon" className={styles.logo} />
      {/* Hamburger menu icon */}
      <div className={styles.hamburger} onClick={toggleNav}>
        <div className={styles.icon}></div>
        <div className={styles.icon}></div>
        <div className={styles.icon}></div>
      </div>
      {/* Navigation menu */}
      <nav className={`${styles.navigation} ${isNavVisible ? styles.navActive : ""}`}>
        <Link to="/" className={styles.navItem} onClick={() => setIsNavVisible(false)}>
          Home
        </Link>
        <Link to="/about-us" className={styles.navItem} onClick={() => setIsNavVisible(false)}>
          About Us
        </Link>
        <Link to="/roadmap" className={styles.navItem} onClick={() => setIsNavVisible(false)}>
          Road Map
        </Link>
        <Link to="/gamefi" className={styles.navItem} onClick={() => setIsNavVisible(false)}>
          GameFi
        </Link>
        <Link to="/hornyjail" className={styles.navItem} onClick={() => setIsNavVisible(false)}>
          HornyJail
        </Link>
        <Link to="/mintout" className={styles.navItem} onClick={() => setIsNavVisible(false)}>
          NFT Collection
        </Link>
        {/* ... other nav items ... */}
      </nav>
    </header>
  );
}

export default Header;
