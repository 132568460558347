// CountdownTimer.js
import React, { useState, useEffect } from "react";
import styles from "./CountdownTimer.module.css"; // Make sure to create a corresponding CSS module file

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timerTimeout = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearTimeout(timerTimeout);
  }, [timeLeft, targetDate]);

  function calculateTimeLeft(targetDate) {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <div className={styles.countdown}>
      {Object.keys(timeLeft).length ? (
        Object.keys(timeLeft).map((interval) => (
          <div key={interval} className={styles.timerSegment}>
            <span className={styles.number}>{timeLeft[interval]}</span>
            <span className={styles.interval}>{interval}</span>
          </div>
        ))
      ) : (
        <span className={styles.timesUp}></span>
      )}
    </div>
  );
};

export default CountdownTimer;
