import React from "react";
import styles from "./MintOut.module.css"; // Ensure this CSS file includes the styles for your MintOut section
import backgroundVideo from "../../assets/BONK-KARDS.mp4"; // Import the background video

const MintOut = () => {
  return (
    <div className={styles.MintOut}>
      <video autoPlay loop muted playsInline className={styles.backgroundVideo}>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h1 className={styles.MintOutTitle}></h1>
      {/* Insert the rest of your MintOut content here */}
    </div>
  );
};

export default MintOut;
