import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";
import Roadmap from "./components/Roadmap/Roadmap";
import NFT from "./components/NFT/NFT";
import GameFi from "./components/GameFi/GameFi";
import HornyJail from "./components/Horny Jail/HornyJail";
import Footer from "./components/Footer/Footer";
import MintOut from "./components/MintOut/MintOut";
import "./App.css";

function ContentWithFooter() {
  const location = useLocation();
  const isNFTCollectionPage = location.pathname === "/nft-collection";

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/nft-collection" element={<NFT />} />
        <Route path="/gamefi" element={<GameFi />} />
        <Route path="/hornyjail" element={<HornyJail />} />
        <Route path="/mintout" element={<MintOut />} />
        {/* ... other routes ... */}
      </Routes>
      {!isNFTCollectionPage && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="site-wrapper">
        <Header />
        <ContentWithFooter /> {/* This new component includes Routes and the Footer */}
      </div>
    </Router>
  );
}

export default App;
