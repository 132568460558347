import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import styles from "../NFT/NFT.module.css";

const truncate = (input, len) => (input.length > len ? `${input.substring(0, len)}...` : input);

export const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  width: 212.53px;
  height: 69.83px;
  left: 613.74px;
  top: 403.69px;

  /* Green/G300 */

  background: #e25eef;
  /* Green/G900 */

  border: 2px solid #263f2a;
  box-sizing: border-box;
  /* Bevels/Green */
  box-shadow: inset 0px 4px 0px #f484ff, inset 0px -4px 0px #f484ff;
  border-radius: 8px;

  position: static;
  width: 130px;
  height: 40px;
  left: 41.26px;
  top: 14.91px;

  /* Buttons/Bold */

  font-family: "Munro"; /* Define a name for the font */
  src: url("../../assets/fonts/munro.ttf"); /* Path to the font file */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  /* Neutrals/N10 */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
`;

export const StyledRoundButton = styled.button`
  /* border-radius: 100%;
  border: none;
  background-color: #71c638;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgba(250, 250, 250, 0.3) 0px 4px 0px -2px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 8px 24px; */
  width: 212.53px;
  height: 69.83px;
  left: 613.74px;
  top: 403.69px;
  background: #e25eef;
  border: 2px solid #263f2a;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 0px #f484ff, inset 0px -4px 0px #f484ff;
  border-radius: 101px;
  /* position: static; */
  width: 50px;
  height: 40px;
  left: 41.26px;
  top: 14.91px;
  font-family: "Munro"; /* Define a name for the font */
  src: url("../../assets/fonts/munro.ttf"); /* Path to the font file */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 116px;
  /* Frame 1 */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 14.1838px 4.43243px;

  position: absolute;
  width: 84.4px;
  height: 35.9px;
  left: 55.44px;
  top: 31.2px;

  background: #f9d745;
  border: 1.32973px solid #000000;
  box-sizing: border-box;
  border-radius: 0px;
  @media (min-width: 767px) {
    width: 116px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const HeadingMain = styled.h1`
  font-size: 200px;
  font-weight: 400;
  line-height: 223.53px;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 100px;
    line-height: 111px;
  }
  @media (max-width: 425px) {
    font-size: 50px;
    line-height: 55px;
  }
`;
export const FooterImg = styled.img`
  width: 70%;
  height: auto;
  @media (max-width: 767px) {
    width: 70%;
    height: auto;
  }
  @supports (-webkit-touch-callout: none) {
    width: 100%;
    height: auto;
  }
`;
export const FooterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Styledsocial = styled.img`
  width: 36px;
  height: 29px;
  margin-left: 30px;
  @media (min-width: 767px) {
    width: 36px;
    height: 29px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const Styledsocialleft = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const StyledsocialRight = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledImg = styled.img`
  width: 150px;
  height: 90px;
  @media (min-width: 900px) {
    width: 150px;
    height: 90px;
  }
  @media (min-width: 1000px) {
    width: 150px;
    height: 90px;
  }
  @media (max-width: 767px) {
    position: absolute;
    top: 25vh;
    right: 0;
    display: none;
  }
`;
export const StyledImgOther = styled.img`
  width: 162px;
  height: 135px;
  @media (min-width: 900px) {
    width: 162px;
    height: 135px;
  }
  @media (max-width: 767px) {
    position: absolute;
    top: 20vh;
    left: 0;
    display: none;
  }
  transition: width 0.5s;
`;
export const StyledIcon = styled.ul`
  display: flex;
  /* position: absolute; */
`;
export const IconItem = styled.li`
  flex-direction: row;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const HeaderText1 = styled.p`
  text-align: center;
  font-family: "Munro"; /* Define a name for the font */
  src: url("../../assets/fonts/munro.ttf"); /* Path to the font file */
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  color: rgb(255, 255, 255);
  @media (max-width: 555px) {
    font-size: 50px;
  }
`;

export const HeaderText2 = styled.p`
  font-family: "Munro"; /* Define a name for the font */
  src: url("../../assets/fonts/munro.ttf"); /* Path to the font file */
  font-style: normal;
  font-weight: 400;
  font-size: 31.6467px;
  letter-spacing: 0.17em;
  color: rgb(255, 255, 255);
  text-align: center;
  @media (max-width: 555px) {
    font-size: 20px;
  }
`;

function NFT() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const baseStyle = {
    position: "absolute",
    width: "100%",
    objectFit: "cover",
    zIndex: 1,
  };

  // Extend the base style with mobile-specific styles
  const mobileStyle = {
    ...baseStyle,
    height: "95%", // Adjusted height for mobile view
  };

  // Use window.innerWidth or a state with useEffect to determine if it's a mobile view
  // Here's a simple example using window.innerWidth directly
  const isMobile = window.innerWidth <= 768; // or another breakpoint you prefer

  // Choose the style based on whether it's mobile
  const videoStyle = isMobile ? mobileStyle : { ...baseStyle, height: "100%" };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: null,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`WOW, the ${CONFIG.NFT_NAME} is yours!`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container flex={1}>
        {CONFIG.SHOW_BACKGROUND && (
          <video autoPlay loop muted playsInline style={videoStyle}>
            <source src="/config/images/giphy.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        <ResponsiveWrapperHeader>
          <a href="" target="_blank">
            {/* <StyledLogo alt={"logo"} src={"/config/images/logo.png"} height="60px" width="116px" /> */}
          </a>
        </ResponsiveWrapperHeader>

        <ResponsiveWrapper flex={1} test>
          <s.Container flex={1} style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
            {/* <HeaderText1>Fantom Boys Club NFT</HeaderText1> */}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            // style={{ paddingTop: "50px" }}
          >
            <s.TextTitle>
              {/* Fantom Boys Club NFT */}
              <HeaderText1>Fantom Bonk Inu</HeaderText1>
            </s.TextTitle>
            <s.TextTitle>
              <HeaderText2>Bonk Themed cards built on the Fantom blockchain.</HeaderText2>
            </s.TextTitle>
            <HeadingMain>
              {data.totalSupply}/{CONFIG.MAX_SUPPLY}
            </HeadingMain>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>

                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}>6.9 FTM</s.TextTitle>

                <s.SpacerSmall />
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>

                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          padding: "24px",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>

                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>

                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        style={{ marginTop: "10px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BONK"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"start"} ai={"start"}></s.Container>
        </ResponsiveWrapper>

        <s.Container jc={"center"} ai={"center"}>
          <FooterDiv>
            {/* <FooterImg src={"/config/images/bottomLogo.png"} alt="Characters" />
            <FooterImg src={"/config/images/bottomLogo.png"} alt="Characters" /> */}
          </FooterDiv>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default NFT;
